@import '../../../base'

.accounting-container
  width: 1110px
  > .ant-card
    height: 100%
    border-color: #e4e8eb
  .ant-card-body
    padding: 0
    height: 100%


.accounting
  height: 100%
  .ant-tabs-content
    overflow: auto
    .ant-spin
      position: absolute
      width: 100%
      top: 0
      left: 0
    .ant-tabs-tabpane
      height: auto
